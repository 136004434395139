import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Hero from '../components/heroName'

import Portrait from '../components/portrait'

import indexStyles from './index.module.scss'

const IndexPage = () => {

  return (
    <>
      <Hero />
      <Layout>
        <SEO title="Home" />
        <div className={indexStyles.intro}>
          <div>
            <Portrait />
          </div>
          <div className={indexStyles.artDirected}>
            <p>Hello, I'm Byron.</p>
            <p>I'm a creative technologist who solves problems by building beautiful experiences and meaningful&nbsp;relationships.</p>
            <p>In my current role at Wipro Digital, I use design, engineering and strategy to transform Fortune 500 companies, with solutions affecting millions&nbsp;worldwide.</p>
            <p>I help clients navigate from ideation to finding product-market fit, with the needs of their customers at the heart of everything we&nbsp;do.</p>

            <div className={indexStyles.skillContainer}>
              <span>HTML5</span>
              <span>CSS3</span>
              <span>Javascript</span>
              <span>React</span>
              <span>Gatsby</span>
              <span>ExpressJS</span>
              <span>GSAP</span>
              <span>D3JS</span>
              <span>Git</span>
              <span>Gulp</span>
              <span>Webpack</span>
              <span>Design Thinking</span>
              <span>Creative Problem Solving</span>
              <span>Customer Journey Mapping</span>
              <span>Human Centred Design</span>
              <span>Hypothesis-Driven Design</span>
              <span>Adobe CC</span>
              <span>Sketch</span>
              <span>FramerX</span>
              <span>Value Innovation</span>
              <span>Lean Methodologies</span>
              <span>Discovery-Driven Planning</span>
              <span>Agile Methodologies</span>
              <span>Assumption Mapping</span>
              <span>Design Sprints</span>
              <span>Rapid Prototyping</span>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );

}

export default IndexPage
