import React from 'react'

import { useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it

import portraitStyles from './portrait.module.scss'

const AuthorImg = () => {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "id.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <div className={portraitStyles.portrait}>
            <Img fluid={data.file.childImageSharp.fluid} alt="Me" />
        </div>
    )

}

export default AuthorImg