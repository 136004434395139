import React from 'react'
import { Link } from 'gatsby'

import heroStyles from './heroName.module.scss'

const Hero = () => {
    return (
        <div className={heroStyles.heroName}>
            <h1><Link to='/'>BYRON WONG</Link></h1>
        </div>
    );
}

export default Hero